<template>
  <div class="">
    <b-tabs content-class="custom-tab-inner-content">
      <b-tab :title-item-class="'custom-tab-inner-li'">
        <template #title>
          <span
            v-b-popover.hover.bottomright="
              $t('employeeTabs.mainTabs.basicData')
            "
          >
            <img
              :src="require('@/assets/images/employee-data-basic.svg')"
              :onerror="`this.src='${defaultImg}'`"
              class="icon-tab"
            />
            <span>{{ $t("employeeTabs.mainTabs.basicData") }}</span></span
          >
        </template>
        <EmployeeBasicData
          :employeeData="employeeData"
          :disabledStatus="true"
        />
      </b-tab>

      <b-tab :title-item-class="'custom-tab-inner-li'">
        <template #title>
          <span
            v-b-popover.hover.bottomright="
              $t('employeeTabs.mainTabs.personalData')
            "
          >
            <img
              :src="require('@/assets/images/employee-data-personal.svg')"
              :onerror="`this.src='${defaultImg}'`"
              class="icon-tab"
            />
            <span>{{ $t("employeeTabs.mainTabs.personalData") }}</span></span
          >
        </template>
        <EmployeePersonalData
          :employeeData="employeeData"
          :imageSrc="imageSrc"
          :disabledStatus="true"
        />
      </b-tab>

      <b-tab :title-item-class="'custom-tab-inner-li'">
        <template #title>
          <span
            v-b-popover.hover.bottomright="
              $t('employeeTabs.mainTabs.identifiersCardsData')
            "
          >
            <img
              :src="require('@/assets/images/employee-data-cards.svg')"
              :onerror="`this.src='${defaultImg}'`"
              class="icon-tab"
            />
            <span>{{
              $t("employeeTabs.mainTabs.identifiersCardsData")
            }}</span></span
          >
        </template>
        <EmployeeIdentifiersCardsData
          :employeeData="employeeData"
          :disabledStatus="true"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { DEFAULT_IMG } from "@/utils/constants";
import EmployeePersonalData from "./employeeFormComponents/EmployeePersonalData.vue";
import EmployeeBasicData from "./employeeFormComponents/EmployeeBasicData.vue";
import EmployeeIdentifiersCardsData from "./employeeFormComponents/EmployeeIdentifiersCardsData.vue";

export default {
  components: {
    EmployeePersonalData,
    EmployeeBasicData,
    EmployeeIdentifiersCardsData,
  },
  data() {
    return {
      defaultImg: DEFAULT_IMG,
    };
  },
  props: ["employeeData", "imageSrc"],
  methods: {},
  watch: {},
  async created() {},
};
</script>
