<template>
  <div>
    <EmployeeFormInfo :employeeData="employee" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { STATUS } from "@/utils/constants";
import EmployeeFormInfo from "@/components/employees/employees/EmployeeFormInfo.vue";
import Employee from "@/models/employees/employees/Employee";
import createToastMixin from "@/utils/create-toast-mixin";
import { BASE_URL } from "@/utils/constants";

export default {
  name: "EmployeeInfo",
  mixins: [createToastMixin],
  components: {
    EmployeeFormInfo,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
    imageSrc: function () {
      if (this.employee.employeeImagePath) {
        return BASE_URL + this.employee.employeeImagePath;
      }
      return this.employee.defaultImg;
    },
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employee: new Employee(),
    };
  },
  methods: {
    async getEmployeeDetails() {
      this.isLoading = true;
      this.employee.employeeToken = this.employeeToken;
      try {
        let response = await this.employee.getEmployeeDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.employee.fillData(response.data.employeeData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "Employees" }).catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "Employees" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployeeDetails();
  },
};
</script>
